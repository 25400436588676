.pageLoader {
	height: 100vh;
	width: 82%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	.MuiCircularProgress-root {
		z-index: 9999;
	}
}
.datatable .pageLoader {
	background-color: transparent !important;
}
.insightsLoader {
	left: calc(100% - 220px) !important;
	top: 2em !important;
	width: 10% !important;
}
