.multi-select {
	// max-width: 250px;
	min-width: 250px;
	--rmsc-main: var(--primary-theme) !important; //
	--rmsc-hover: #f1f3f5 !important;
	--rmsc-selected: #e2e6ea !important;
	--rmsc-border: #ccc !important;
	--rmsc-gray: #aaa !important;
	// --rmsc-bg: #fff;
	--rmsc-p: 10px !important;
	/* Spacing */
	--rmsc-radius: 25px !important;
	.dropdown-container {
		background-color: var(--page-bg-color) !important;
	}
	.dropdown-container .dropdown-content input {
		background-color: var(--page-bg-color);
	}
	/* Radius */
	.dropdown-container {
		height: var(--dropdown--height);
		.dropdown-heading-value {
			background-color: transparent;
			// height: 82%;
			font-size: 16px;
			height: 82%;
			padding-left: 0.6rem;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}
		svg {
			background-color: transparent;
			color: var(--primary-theme);
		}
	}
	--rmsc-h: 50px !important;
	/* Height */
}

.multi-select-dark {
	// max-width: 250px;
	min-width: 250px;
	--rmsc-main: var(--primary-theme) !important; //
	--rmsc-hover: #595b5d !important;
	--rmsc-selected: #9149ff29 !important;
	--rmsc-border: #ccc !important;
	--rmsc-gray: #aaa !important;
	--rmsc-bg: #2f2f2f !important;
	--rmsc-p: 10px !important;
	--rmsc-radius: 25px !important;
	.dropdown-container {
		background-color: inherit !important;
	}
	.rmsc .dropdown-content .panel-content {
		background-color: #2f2f2f !important;
	}
	.dropdown-container .dropdown-content input {
		background-color: inherit;
		z-index: 100;
	}
	.dropdown-container {
		height: var(--dropdown--height);
		.dropdown-heading-value {
			background-color: transparent;
			height: 82%;
			padding-left: 0.6rem;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}
		svg {
			background-color: transparent;
			color: var(--primary-theme);
		}
	}
	--rmsc-h: 50px !important;
	/* Height */
}

.multi-select-dark {
	// max-width: 250px;
	min-width: 250px;
	--rmsc-main: var(--primary-theme) !important; //
	--rmsc-hover: #595b5d !important;
	--rmsc-selected: #9149ff29 !important;
	--rmsc-border: #ccc !important;
	--rmsc-gray: #aaa !important;
	--rmsc-bg: #2f2f2f !important;
	--rmsc-p: 10px !important;
	--rmsc-radius: 25px !important;
	.dropdown-container {
		background-color: inherit !important;
	}
	.rmsc .dropdown-content .panel-content {
		background-color: #2f2f2f !important;
	}
	.dropdown-container .dropdown-content input {
		background-color: inherit;
		z-index: 100;
	}
	.dropdown-container {
		height: var(--dropdown--height);
		.dropdown-heading-value {
			background-color: transparent;
			height: 82%;
			padding-left: 0.6rem;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}
		svg {
			background-color: transparent;
			color: var(--primary-theme);
		}
	}
	--rmsc-h: 50px !important;
	/* Height */
}

.multi-select-dark {
	// max-width: 250px;
	min-width: 250px;
	--rmsc-main: var(--primary-theme) !important; //
	--rmsc-hover: #595b5d !important;
	--rmsc-selected: #2f2f2f !important;
	--rmsc-border: #ccc !important;
	--rmsc-gray: #aaa !important;
	--rmsc-bg: #2f2f2f !important;
	--rmsc-p: 10px !important;
	--rmsc-radius: 25px !important;
	.dropdown-container {
		background-color: inherit !important;
	}
	.rmsc .dropdown-content .panel-content {
		background-color: #2f2f2f !important;
	}
	.dropdown-container .dropdown-content input {
		background-color: inherit;
		color: white;
		z-index: 100;
	}
	.dropdown-container {
		height: var(--dropdown--height);
		.dropdown-heading-value {
			background-color: transparent;
			height: 82%;
			padding-left: 0.6rem;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}
		svg {
			background-color: transparent;
			color: var(--primary-theme);
		}
	}
	--rmsc-h: 50px !important;
	/* Height */
}

.dropdown-container .dropdown-content ul {
	overflow-x: hidden;
	overflow-y: auto;
	overflow-wrap: anywhere;
}

.dropdown-heading {
	height: 42px !important;
}

.dropdown-container {
	// z-index: 10000 !important;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 30px;
	border: 1px solid #ccc;
	width: 100%;
	padding-left: 0.5;
	background: none;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 0.5rem;
	text-align: left;
}

.dropdown-container .gray {
	font-size: 0.9rem !important;
	color: #696969;
	font-size: 16px;
}

.dropdown-container .dropdown-content {
	// z-index: 2;
}

.PDFColumns .multi-select {
	--rmsc-radius: 15px !important;
}
