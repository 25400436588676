.upgrade-popup {
	display: flex;
	width: 100%;
	// height: 500px;]
	// background: #111827;
	// color: white;
	border-radius: 10px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	overflow: hidden;

	.left-section {
		width: 50%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 16px;
		}

		.subtitle {
			font-size: 14px;
			// color: #9ca3af;
			margin-bottom: 16px;
			font-weight: 400;
		}

		.features-list {
			list-style: none;
			padding: 0;
			margin: 0;
			// color: #d1d5db;
			font-size: 14px;

			.feature-item {
				display: flex;
				align-items: start;
				gap: 8px;
				margin-bottom: 12px;

				p {
					// color: #9ca3af;
					margin: 0;
				}
			}
		}

		.upgrade-button {
			margin-top: 20px;
			padding: 10px 16px;
			// background: #2563eb;
			// color: white;
			border: none;
			border-radius: 20px;
			font-weight: 400;
			cursor: pointer;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
			font-size: 18px;
			width: 350px;
		}
	}

	.right-section {
		width: 50%;
		// background: #1e293b;
		display: flex;
		justify-content: center;
		align-items: center;

		.upgrade-image {
			width: 100%;
			height: auto;
			border-radius: 8px;
			// box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		}
	}

	.lightColor {
		color: '#BCBCBD';
	}
}
