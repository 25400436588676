@import '../../utils/variables.scss';

.ReactDateTimePicker {
	.MuiPaper-root {
		position: absolute;
		top: 20px !important;
	}
	.MuiTypography-root {
		// font-size: 12px !important;
	}

	display: inline-flex;
	align-items: center;
	background-color: white;
	padding: 0rem 1rem 0rem 0.8rem;
	height: fit-content;
	border-radius: 50px;
	box-shadow: 0px 5px 12px #0000001a;
	font-size: 13px;
	float: right;
	cursor: pointer;

	&__small {
		width: 206px;
	}

	// &__large {
	// 	width: 275px;
	// }

	&__customComponent {
		position: absolute;
		top: 21rem;
		left: 20px;
		// border: 2px solid red;
		// background-color: yellow;

		.MuiTypography-root {
			font-size: 0.9rem;
		}
	}

	&__customComponentQuatre {
		position: absolute;
		top: 29rem;
		left: 20px;
		// border: 2px solid red;
		// background-color: yellow;

		.MuiTypography-root {
			font-size: 0.9rem;
		}
	}

	img {
		width: 1.2rem;
		height: 1.2rem;
	}

	&__displayLabel {
		display: flex;
		align-items: center;
		font-weight: 600;
		padding: 0.2rem 0rem;

		span {
			font-weight: 600;
		}
	}

	&__compareRange {
		color: gray;
	}

	&__menu {
		position: absolute;
		top: 0px !important;
		.rdrDateRangePickerWrapper {
			border-radius: 20px;
			padding: 0.1rem;
		}
	}

	&__button {
		float: right;
		// width: 39.6em;
		padding: 0.833em 0.5em 0.833em 0;
		// border-left: solid 1px #eff2f7;
		display: flex;
		align-items: center;
		justify-content: end;
	}
}

@media screen and (max-width: 822px) {
	.ReactDateTimePicker .MuiButtonBase-root {
		padding: 12px 4px;
	}
}

.react-datepicker-popper {
	width: max-content;
	z-index: 1000;
	padding-top: 0px !important;
}

.react-datepicker__time-list-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-datepicker__day--today {
	background-color: transparent;
	color: black;
}

.rdrDefinedRangesWrapper {
	// width: 196px !important;
	width: 250px !important;
}

.rdrCalendarWrapper {
	max-width: 40rem;
}

// .ReactDateTimePicker .MuiPaper-root{
//     left: 0px !important;
//     right: 8rem !important;
//     top: '128px' !important;
// }
.ReactDateTimePickerRange {
	min-width: 250px !important;
}

.ReactFilterDateRangePicker {
	.rdrStaticRangeLabel {
		// background-color: aqua;
	}
}

.rdrStaticRangeLabel {
	font-weight: 700;
}
//  ************************** under dark theme -:
.ReactFilterDateRangePickerDark {
	.rdrStaticRangeLabel {
		background-color: #2f2f2f;
		color: white !important;
	}

	// inputs for dates
	.rdrDateDisplayItem input {
		color: white;
	}

	//  month / year pickers dropdowns
	.rdrMonthAndYearPickers select option {
		background-color: #161b22;
		color: white;
	}

	.rdrMonthAndYearPickers select {
		color: #bfbfbf;
	}

	.rdrDayDisabled {
		background-color: rgb(52, 52, 52);
		opacity: 0.5;
	}

	.rdrMonthAndYearPickers select {
		// background: #757575;
		// color: #e0e0e0;
	}

	.rdrDateDisplayWrapper {
		background-color: #2f2f2f;
	}

	.rdrDateDisplayItem {
		background-color: #161b22;
	}

	.rdrMonthAndYearWrapper {
		background-color: #2f2f2f;
	}

	.rdrMonths {
		background-color: #2f2f2f;
	}

	.rdrDayPassive {
		color: #e0e0e0a0;
	}

	.rdrDayNumber > span {
		color: white;
	}

	.rdrDefinedRangesWrapper {
		background-color: #2f2f2f;
	}

	.rdrCalendarWrapper,
	.rdrDateRangeWrapper {
		background-color: #e0e0e0;
		height: 100%;
	}

	.rdrStaticRange:hover .rdrStaticRangeLabel,
	.rdrStaticRange:focus {
		background-color: #161b22;
		color: var(--primary-theme);
	}

	.rdrDefinedRangesWrapper .rdrStaticRangeSelected > span {
		color: var(--secondary) !important;
	}
}
.dropdown {
	&__textExceed {
		font-size: 14px !important;
		font-family: 'Lato';
	}
}
