.filterModal {
	// overflow: hidden;
	align-self: center;
	&__modalContainer {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 70vw;
		min-height: 60vh;
		padding: 1.8rem 2rem 1.4rem 2.4rem;
		// background: #fff;
		background: #24282f;
		border-radius: 20px;
		&__dimension {
			max-height: 38vh;
			overflow-y: scroll;
			padding: 0.5rem 0rem !important;
		}
		.multi-select {
			max-width: 350px;
		}
	}
	&__closeBtn {
		position: absolute !important;
		padding: 0px !important;
		top: -4px !important;
		right: -6px !important;
		color: #000000 !important;
		svg {
			background-color: white;
			border-radius: 4rem;
		}
	}
	&__conditions {
		&__item {
			padding-right: 0.5rem;
		}
		&__selected {
			min-width: 42px !important;
			align-items: start;
			justify-content: center;
			display: flex;
			width: 30% !important;
			min-height: var(--dropdown--height) !important;
			height: var(--dropdown--height) !important;
			padding: 0px !important;
			border-radius: 1rem !important;
			border: 2px solid var(--primary-theme) !important;
			color: var(--primary-theme) !important;
		}
		.MuiTabs-indicator {
			height: 0px !important;
		}
		&__relations {
			display: flex;
			align-items: center;
			.MuiTabs-flexContainer {
				justify-content: space-evenly;
			}
			&__button {
				min-width: 44px !important;
				align-items: start;
				justify-content: center;
				display: flex;
				width: 30% !important;
				min-height: var(--dropdown--height) !important;
				// height: var(--dropdown--height) !important;
				min-height: 40px !important;
				padding: 0px !important;
				border-radius: 0.8rem !important;
				border: 1px solid #bfbfbf !important;
				color: var(--primary-theme) !important;
				// :hover {
				// 	border: 1px solid black !important;
				// }
			}
			&__delete {
				width: 1rem;
			}
			&__deleteButton {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid grey;
				border-radius: 50%;
				width: 2.5rem;
				height: 2.5rem;
			}
		}
		&__seprator {
			width: inherit;
			content: '\00a0\00a0\00a0\00a0\00a0';
			text-align: center;
			overflow: hidden;
			margin: 0.2rem 0.5rem 0.2rem 0.4rem;
			font-size: 18px;
			color: var(--primary-theme);
			::after,
			::before {
				background-color: #bfbfbf;
				content: '';
				display: inline-block;
				height: 1px;
				position: relative;
				vertical-align: middle;
				width: 50%;
			}
			::before {
				margin-left: -40%;
				margin-right: 0.5rem;
			}
			::after {
				margin-right: -40%;
				margin-left: 0.5rem;
			}
		}
	}
	// .MuiBadge-root .MuiBadge-badge{
	//   position: relative;
	//   top: 0.8rem;
	//   right: 1.5rem;
	// }
}

.socialMedia {
	&__modalContainer {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 70vw;
		min-height: auto;
		padding: 1.8rem 2rem 1.4rem 2.4rem;
		background: #fff;
		border-radius: 20px;
		&__dimension {
			max-height: 38vh;
			overflow-y: scroll;
			padding: 0.5rem 0rem !important;
		}
	}
}

.circlePosition {
	&__svg {
		color: #cc3333 !important;
		width: 1.1rem !important;
		height: 1.1rem !important;
	}
	position: absolute;
	top: -4px;
	right: 1rem;
	z-index: 10;
	text-align: right;
	cursor: pointer;
}

.separator {
	width: inherit;
	content: '\00a0\00a0\00a0\00a0\00a0';
	text-align: center;
	overflow: hidden;
	// margin: 0.5rem 1rem 0.5rem 0.2rem;
	font-size: 18px;
	color: var(--primary-theme);
	::after,
	::before {
		background-color: #bfbfbf;
		content: '';
		display: inline-block;
		height: 1px;
		position: relative;
		vertical-align: middle;
		width: 50%;
	}
	::before {
		margin-left: -40%;
		margin-right: 0.5rem;
	}
	::after {
		margin-right: -40%;
		margin-left: 0.5rem;
	}
}

.filter_dropdown_container {
	position: relative;
	.clear_btn_icon {
		cursor: pointer;
		position: absolute;
		right: -10%;
		top: 20%;
		// transition: all 0.2s ease-in-out;
		// &:hover{
		//   transform: rotate(180deg);
		// }
	}
}

.filterIcon {
	padding: 10px;
}

.filterIcon:hover {
	background-color: rgba(145, 73, 255, 0.04) !important;
	border-radius: 25px;
	padding: 10px;
	.iconColor {
		// filter: brightness(0) invert(1);
	}
}

// ============ media query
@media screen and (max-width: 1366px) {
	.circlePosition {
		top: -4px;
		right: 1.5rem;
	}
}

@media screen and (min-width: 1920px) {
	.circlePosition {
		top: -4px;
		right: 1rem;
	}
}

// @media screen and (max-width: 1441px) {
// 	.MuiTabs-flexContainer {
// 		justify-content: space-between !important;
// 	}
// }
