// Change this for page headings
.app {
	display: flex;
	&__outer {
		width: 100%;
		height: 100vh;
		background-color: #f4f7fc;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-y: hidden;
		overflow-x: hidden;
		// Firefox Scrollbar
		scrollbar-color: var(--scrollbar-color) transparent !important;
		scrollbar-width: thin;
	}
	&__embeded {
		height: 100vh !important;
		margin-top: 0rem !important;
	}
}

.commonPageHeading {
	color: var(--pageHeading);
	padding: 0.5rem 1rem;
	font-size: 24px;
	font-weight: 600;
	// height: 74px;
	// background-color: white;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.pageheadingFixed {
	display: flex;
	align-items: center;
	width: -webkit-fill-available;
	width: -moz-available;
	z-index: 2;
}

.marginForFixedHeading {
	margin-top: 2rem;
	overflow-y: auto;
	height: calc(100% - var(--margin-For-FixedHeading-height));
}

.commonSubHeading {
	padding: 1rem;
	font-size: 20px;
	float: left;
	font-weight: 600;
}

.notDataFoundList {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: darkslategrey;
	overflow: hidden;
}

.notDataFoundList .MuiSvgIcon-root {
	color: slategray;
}
.show_marque_block {
	// display: block;
	// padding-top: 20px;
}
@media screen and (max-width: 1366px) {
	.commonPageHeading {
		// height: 88px;
		height: var(--current-header-height, 88px); // Default height
	}
	.marginForFixedHeading {
		margin-top: 0rem;
		// commonpage heading - padding - header
		height: calc(100% - var(--margin-For-FixedHeading-height));
	}
}

::-webkit-scrollbar {
	width: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: var(--scrollbar-color);
	border-radius: 10px;
}

.MuiPaper-root {
	border-radius: 15px !important;
}

.headerBorderRadius {
	border-radius: 0px !important;
}

.main-div-app {
	margin-top: 28px;
	position: fixed;
	width: 100%;
}

.disabled {
	opacity: 0.4;
	pointer-events: none;
}
