.pinIcon {
	border: none !important;
}

.pinIcon:hover {
	background-color: white;
}

.bookmark-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
}

.bookmark-content-display {
	display: block;
}

.bookmark-list {
	// padding: 0rem 0rem 0rem !important;
	padding: 12px;
	top: 95px;
	width: 225px;
	display: flex;
	position: fixed;
	border-radius: 22px;
	background-color: white;
	max-height: 250px;
	box-shadow: 0px 25px 30px rgb(0 0 0 / 10%);
	//border-radius: 20px;
	background: var(--page-bg-color) 0% 0% no-repeat padding-box;
	cursor: default;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	margin-inline: 10px;
	overflow: inherit;
	z-index: 999;
}

.MuiListItemSecondaryAction-root {
	right: -26px !important;
}

hr.solid {
	border-top: 1px solid black;
}

.addBookmark > span {
	font-weight: 800;
	font-size: 18px;
	// color: var(--pageHeading);
}

.bookmark-button {
	// border: 2px solid rgb(232, 232, 232);
	border-left: none;
	// background: #bfbfbf3d;
	display: 'flex';
	align-items: 'center';
	font-size: '20px';
	font-weight: 'bold';
}

.bookmarkButtons:hover {
	background-color: white;
}

.bookmark-list > ul {
	width: 100%;
	/* height: 100%; */
	padding-top: 0;
	max-height: 250px;
	padding-bottom: 0;
}

.pinIcon {
	.MuiButton-startIcon {
		margin-right: 0;
		margin-left: 0;
	}
}

.listItem {
	// color: grey;
	padding-right: 6px !important;
}

.bookmarkListText > span {
	max-width: 170px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.MuiTableCell-stickyHeader {
	z-index: 1 !important;
}

.bookmarkListText {
	text-overflow: ellipsis;
	min-width: 150px;
	white-space: nowrap;
	width: 50px;
	overflow: hidden;
}
