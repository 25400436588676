.switchAccount {
	align-self: center;
	position: relative;
	padding: 10px 0px 10px 0px; //mm
	// padding: 10px;
	cursor: pointer;
	display: flex;
	font-size: 25px;
	justify-content: center; //mm
	align-items: center; //mm
}

.switchAccount:hover {
	// background-color: black;
	border-radius: 25px;
	padding: 10px 0px 10px 0px; //mm
	// padding: 10px;
	background-color: rgba(145, 73, 255, 0.04);
}
