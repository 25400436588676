// @import "./utils/mixins.scss";
@import "./utils/variables.scss";


body {
  margin: 0;
  font-family: Lato !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.paragraphText {
  font-size: 17px !important;
  font-family: Lato !important;
  letter-spacing: 0.08px !important;
  line-height: 1.68 !important;
  font-stretch: normal !important;
  font-weight: normal !important;
}

